<template>
  <v-container fluid pa-0 ma-0>
    <!-- <h3 class="ml-6"> -->
      <v-toolbar flat color="#fafafa">
        <v-toolbar-title>
          Master of Multiple Meanings
        </v-toolbar-title>
        <!-- <v-divider class="mx-2" inset vertical></v-divider> -->
            <download-excel
          :data="ExportSynonym"
          name="Master of Multiple.xls"
        >
        
      <v-tooltip right>
        <template v-slot:activator="{ on }">
        

       <a-icon class="mx-2" v-on="on" type="file-excel" theme="twoTone" two-tone-color="#52c41a" :style="{ fontSize: '20px' }"/>
        
        </template>
        <span>Export to excel</span>
      </v-tooltip>
</download-excel>
      </v-toolbar>
    
    <!-- </h3> -->
  <v-card flat class="pl-2 ml-2">
    <a-row type="flex" justify="start">
      <a-col :span="13">
        <a-input
          style="width: 100%"
          v-model="searchSynonym"
          append-icon="search"
          outlined
          placeholder="Search keywords"
        ></a-input>
      </a-col>
      <a-col :span="4">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              style="background-color: #455a64; color: white"
              @click="
                AddKeywords();
                saveCondition = 'NEW';
              "
              v-on="on"
            >
              <span style="font-size: 12px">Add Keywords</span>
              <v-icon xs>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Add New Keywords</span>
        </v-tooltip>
      </a-col>

      <a-col :span="4">
        <v-btn
          v-if="enableAdd"
          small
          style="background-color: #455a64; color: white"
          @click="AddItem"
        >
          <span style="font-size: 12px">Add Row</span>
          <v-icon xs>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </a-col>
      <a-col>
        <v-btn
          v-if="enableAdd"
          small
          style="background-color: #455a64; color: white"
          @click="SaveKeywords()"
        >
          <span style="font-size: 12px">Save</span>
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </a-col>
    </a-row>

    <a-row type="flex" justify="start">
      <a-col :span="16">
        <a-table
          :id="ListResponsive()"
          :pagination="pagination"
          :columns="columns"
          :data-source="filteredSynonymData"
          style="overflow-y: auto"
          bordered
          :rowClassName="
            (record, index) => (index === indexOfKeyword ? 'bgBlue' : 'bgWhite')
          "
        >
          <a slot="no" slot-scope="text, record, index">
            <span>{{ index + 1 }}</span>
          </a>

          <a slot="A" slot-scope="text, record">
            {{ record[0] }}
          </a>

          <a slot="B" slot-scope="text, record">
            {{ record[1] }}
          </a>

          <a slot="C" slot-scope="text, record">
            {{ record[2] }}
          </a>

          <a slot="D" slot-scope="text, record">
            {{ record[3] }}
          </a>

          <a slot="E" slot-scope="text, record">
            {{ record[4] }}
          </a>

          <a slot="action" slot-scope="text, record">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon @click="removeSynonym(record.key)" v-on="on"
                  >mdi-close-box</v-icon
                >&emsp;
              </template>
              <span>Delete All Keywords</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon @click="SeeMore(record.key)" v-on="on"
                  >mdi-dots-horizontal-circle</v-icon
                >
              </template>
              <span>See More Keywords</span>
            </v-tooltip>
          </a>
        </a-table>
      </a-col>

      <a-col :span="1"> </a-col>

      <a-col v-if="this.enableAdd">
        <table :id="ListResponsive()">
          <tbody :id="TbodyResponsive()">
            <tr style="background-color: gray; color: #fafafa">
              <td style="border-color: gray"></td>
              <td style="text-align: center; border-color: gray">Keywords</td>

              <td></td>
            </tr>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ index + 1 }}</td>

              <td>
                <input
                  @keyup.enter="AddItem()"
                  type="text"
                  v-model="items[index]"
                />
              </td>
              <td class="text-xs-center">
                <v-icon @click="removeItem(index)"
                  >mdi-close-box-outline</v-icon
                >
              </td>
            </tr>
          </tbody>
        </table>
      </a-col>
    </a-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      enableAdd: 0,
      items: [],
      loading: false,
      searchSynonym: "",
      saveCondition: "",
      selectedData: "",
      indexOfKeyword: -1,
      pagination: {
        onChange: () => {},
        pageSize: 10,
      },
      ExportSynonym:[],
      SynonymData: [
        { 1: "", key: 0 },
        { 2: "", key: 1 },
        { 3: "", key: 2 },
        { 4: "", key: 3 },
        { 5: "", key: 4 },
        { 6: "", key: 5 },
        { 7: "", key: 6 },
        { 8: "", key: 7 },
        { 9: "", key: 8 },
        { 10: "", key: 9 },
      ],
      columns: [
        {
          title: "",
          dataIndex: "no",
          key: "no",
          width: 40,
          scopedSlots: { customRender: "no" },
        },
        {
          title: "A",
          dataIndex: "0",
          key: "0",
          width: 85,
          scopedSlots: { customRender: "A" },
        },
        {
          title: "B",
          dataIndex: "1",
          key: "1",
          width: 85,
          scopedSlots: { customRender: "B" },
        },
        {
          title: "C",
          dataIndex: "2",
          key: "2",
          width: 85,
          scopedSlots: { customRender: "C" },
        },
        {
          title: "D",
          dataIndex: "3",
          key: "3",
          width: 85,
          scopedSlots: { customRender: "D" },
        },
        {
          title: "E",
          dataIndex: "4",
          key: "4",
          width: 85,
          scopedSlots: { customRender: "E" },
        },
        {
          title: "",
          dataIndex: "action",
          key: "action",
          width: 85,
          scopedSlots: { customRender: "action" },
        },
      ],
      json_fields:{}
    };
  },

  created() {
    this.loadSynonymData();
  },

  methods: {
    ListResponsive() {
      let screen = eval(`${window.screen.height} * ${window.devicePixelRatio}`);
      // console.log(screen)
      if (screen == "768") {
        return "responsive768";
      } else if (screen == "1024") {
        return "responsive1024";
      } else if (screen == "1080") {
        return "responsive1080";
      } else if (screen >= "1200") {
        return "responsive1200";
      } else {
        return "responsive";
      }
    },

    TbodyResponsive() {
      let screen = eval(`${window.screen.height} * ${window.devicePixelRatio}`);
      if (screen == "768") {
        return "tbody768";
      } else if (screen == "1024") {
        return "tbody1024";
      } else if (screen == "1080") {
        return "tbody1080";
      } else if (screen >= "1200") {
        return "tbody1200";
      } else {
        return "tbody";
      }
    },

    async AddItem() {
      if (this.enableAdd) {
        await this.items.push("");
        await this.scrollDown();
      }
    },
    removeItem(i) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, Delete !`,
      }).then((result) => {
        if (result.value) {
          this.items.splice(i, 1);
          // let url = `${this.api}update/obsolete?ID=${val._id}`;
          // axios.post(url).then(() => {});

          Swal.fire("Deleted!", "The keyword has been deleted.", "success");
          this.loadSynonymData();
        }
      });
    },

    removeSynonym(i) {
      this.indexOfKeyword = i;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, Delete !`,
      }).then((result) => {
        if (result.value) {
          this.SynonymData.splice(i, 1);

          Swal.fire("Deleted!", "The keyword has been deleted.", "success");
          let url = `${this.api}delete/keywords?index=${i}`;
          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          axios.post(url, this.SynonymData).then(() => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Save Successfully!",
              showConfirmButton: false,
              timer: 750,
            });

            // this.loadSynonymData();
          });
        }
      });
    },

    scrollDown() {
      let elem = document.getElementById(`${this.TbodyResponsive()}`);
      elem.scrollTop = 999999;
    },

    loadSynonymData() {
      this.loading = true;
      let url = `${this.api}get/SynonymChars`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.get(url).then((res) => {
        this.SynonymData = res.data.data.map((rec, index) => {
          let data = {};
          for (const [key, value] of Object.entries(rec)) {
            data[key] = value;
          }
          data.key = index;
          return data;
        });
        // console.log(this.SynonymData)
        this.ExportSynonym = res.data.data
        this.ExportSynonym.sort(function(a, b){
          // ASC  -> a.length - b.length
          // DESC -> b.length - a.length
          return b.length - a.length;
        });
        console.log(this.ExportSynonym)
      // }
        
        this.loading = false;
      });
    },

    SeeMore(index) {
      this.enableAdd = 1;
      this.indexOfKeyword = index;
      this.saveCondition = "";
      this.items = Object.assign([], this.SynonymData[index]);
    },

    AddKeywords() {
      this.indexOfKeyword = -1;
      this.enableAdd = 1;
      this.items = [""];
    },

    SaveKeywords() {
      let url = "";
      if (this.saveCondition == "NEW") {
        url = `${this.api}insert/keywords`;
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        axios.post(url, this.items).then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Save Successfully!",
            showConfirmButton: false,
            timer: 750,
          });
          this.loadSynonymData();
          // this.SynonymData.unshift(this.items);
        });
      } else {
        url = `${this.api}update/keywords?index=${this.indexOfKeyword}`;
        axios.defaults.headers.common["x-api-key"] =
          "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
        axios.post(url, this.items).then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Update Successfully!",
            showConfirmButton: false,
            timer: 750,
          });
          this.loadSynonymData();
        });
      }
      this.enableAdd = 0;
    },

    listColor(index) {
      if (this.indexOfKeyword == index) {
        return "backgroundColor:yellow";
      } else return "backgroundColor:white";
    },
  },
  watch: {
    searchSynonym() {},
  },
  computed: {
    filteredSynonymData() {
      if (!this.searchSynonym) {
        return this.SynonymData;
      } else
        return this.SynonymData.filter((rec) => {
          let search = new RegExp(this.searchSynonym.toUpperCase(), "g");
          return JSON.stringify(Object.values(rec)).toUpperCase().match(search);
        });
    },
  },
};
</script>

<style>
.bgBlue {
  background-color: #a8e5f5;
}
.bgWhite {
  background-color: white;
}

a {
  color: black;
}

table {
  border-collapse: collapse;
  border-style: solid;
}

table td {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding: 15px;
  width: auto;
}

table tr {
  border-collapse: collapse;
  border-style: solid;
}

.ant-table-row-cell-break-word {
  text-align: center;
}

thead.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: center;
  color: #fafafa;
  background-color: gray;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

#responsive {
  max-height: 95vh;
  width: 100%;
  overflow: auto;
}
#responsive768 {
  max-height: 71vh;
  width: 100%;
  overflow: auto;
}
#responsive1024 {
  max-height: 79vh;
  width: 100%;
  overflow: auto;
}
#responsive1080 {
  max-height: 85vh;
  width: 100%;
  overflow: auto;
}
#responsive1200 {
  max-height: 90vh;
  width: 100%;
  overflow: auto;
}

#tbody768 {
  height: 439px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}

#tbody1024 {
  height: 689px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}

#tbody1080 {
  height: 739px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}

#tbody1200 {
  height: 780px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}

#tbody {
  height: 430px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}
</style>
